.message-content {
  /* Base styles */
  font-size: 1rem;
  line-height: 1.5;
}

.message-content a {
  @apply text-blue-600 underline cursor-pointer;
}

.message-content a:hover {
  @apply text-blue-800;
}

.message-content .mention {
  @apply bg-blue-50 text-blue-600 px-2 py-0.5 rounded cursor-pointer;
}

.message-content .mention:hover {
  @apply bg-blue-100;
}

.message-content ul,
.message-content ol {
  @apply pl-6;
}

.message-content ul {
  @apply list-disc;
}

.message-content ol {
  @apply list-decimal;
}

/* Editor styles */
.ql-editor {
  @apply min-h-[100px];
} 
/* Base document styles - shared between editor and display */
.document-editor {
  max-width: 8.5in;
  margin: 0 auto;
  padding: 1in;
  background: white;
  box-shadow: 0 0 10px rgba(0,0,0,0.1);
  min-height: 11in;
  border: 1px solid #ccc;
  border-radius: 4px;
}

/* Content styles - shared between editor and display */
.tiptap-content {
  font-family: "Times New Roman", Times, serif;
  line-height: 1.6;
  color: #000;
  text-rendering: optimizeLegibility;
}

/* Headings */
.tiptap-content h1 {
  font-size: 16px;
  font-weight: bold;
  text-transform: uppercase;
  letter-spacing: 0.05em;
  margin: 2em 0 1.5em;
  text-align: center;
}

.tiptap-content h2 {
  font-size: 14px;
  font-weight: bold;
  margin: 1.5em 0 1em;
}

.tiptap-content h3 {
  font-size: 12px;
  font-weight: bold;
  margin: 1.2em 0 0.8em;
}

/* Paragraphs and Lists */
.tiptap-content p {
  font-size: 12px;
  margin: 0 0 1em;
  text-align: justify;
  hyphens: auto;
}

.tiptap-content ul,
.tiptap-content ol {
  margin: 0 0 1em 0;
  padding: 0 0 0 2em;
}

.tiptap-content li {
  font-size: 12px;
  margin: 0 0 0.5em;
  padding-left: 0.5em;
}

.tiptap-content li p {
  margin: 0;
}

/* Section breaks */
.section-break {
  text-align: center;
  margin: 2em 0;
}

.section-break::before {
  content: "* * *";
  letter-spacing: 0.5em;
}

/* Page break control */
.page-break {
  visibility: hidden;
  page-break-after: always;
  break-after: page;
}

/* Print styles */
@media print {
  /* Reset all margins and padding */
  * {
    margin: 0;
    padding: 0;
  }

  /* Basic page setup */
  @page {
    size: letter;
    margin: 1in;
  }

  /* Clear all decorative styles */
  .document-editor {
    width: auto;
    height: auto;
    margin: 0;
    padding: 0;
    border: none;
    box-shadow: none;
    background: none;
    min-height: 0;
  }

  .tiptap-content {
    width: auto;
    margin: 0;
    padding: 0;
  }

  /* Content spacing */
  .tiptap-content p,
  .tiptap-content ul,
  .tiptap-content ol,
  .tiptap-content h1,
  .tiptap-content h2,
  .tiptap-content h3 {
    orphans: 3;
    widows: 3;
  }

  /* Hide UI elements */
  .editor-menu-bar,
  .ProseMirror-gapcursor,
  .ProseMirror-prompt,
  body > *:not(.document-editor) {
    display: none !important;
  }
}

/* Editor-specific styles */
.ProseMirror {
  outline: none;
  min-height: 9in;
}

.ProseMirror p.is-editor-empty:first-child::before {
  color: #adb5bd;
  content: attr(data-placeholder);
  float: left;
  height: 0;
  pointer-events: none;
}

.editor-menu-bar {
  padding: 0.75rem;
  margin-bottom: 1rem;
  border-radius: 0.5rem;
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem;
  position: sticky;
  top: 0;
  background: #1f2937;
  z-index: 10;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.editor-menu-bar button {
  padding: 0.25rem 0.5rem;
  border: 1px solid #4b5563;
  border-radius: 4px;
  background: #374151;
  color: white;
  cursor: pointer;
  font-size: 0.875rem;
  transition: all 0.2s;
}

.editor-menu-bar button:hover {
  background: #4b5563;
  border-color: #6b7280;
}

.editor-menu-bar button.is-active {
  background: #6b7280;
  border-color: #9ca3af;
  color: white;
}

.editor-menu-bar select {
  padding: 0.25rem 0.5rem;
  border: 1px solid #4b5563;
  border-radius: 4px;
  background: #374151;
  color: white;
  cursor: pointer;
  font-size: 0.875rem;
}

.editor-menu-bar select:hover {
  background: #4b5563;
  border-color: #6b7280;
}

.editor-menu-bar select option {
  background: #1f2937;
  color: white;
}

/* Alpha list style */
.list-alpha {
  list-style-type: lower-alpha;
  margin: 0 0 1em 0;
  padding: 0 0 0 2em;
}

.list-alpha li {
  font-size: 12px;
  margin: 0 0 0.5em;
  padding-left: 0.5em;
}

/* Number list style */
.list-num {
  list-style-type: decimal;
  margin: 0 0 1em 0;
  padding: 0 0 0 2em;
}
  
  .list-num li {
    font-size: 12px;
    margin: 0 0 0.5em;
    padding-left: 0.5em;
  }


/* Client information section */
.client-info {
  margin-bottom: 1.5em;
}

.client-info p {
  margin: 0;
  line-height: 1.2;
}

/* Add modal styles */
.modal-overlay {
  z-index: 50;
}

.modal-content {
  z-index: 51;
} 